<template>
  <div class="home">
    <div class="flexTop">
      <div class="header">
        <div class="leftHead">
          <van-search v-model="valueSerch"
                      shape="round"
                      background="#F4F5F9"
                      :show-action="ShowSerch"
                      @cancel="onCancel"
                      placeholder="输入搜索内容" />
        </div>
        <div v-if="!ShowSerch"
             class="rightHead"
             @click="filterChange">
          <span class="filterText">
            筛选
          </span>
          <i class="iconfont icon-filter1"></i>
        </div>
      </div>
    </div>

    <div class="container">
      <van-list v-model="loading"
                :finished="finished"
                :immediate-check="false"
                finished-text="没有更多了"
                @load="onLoad"
                :offset="10">
        <van-cell class="contentFlex"
                  v-for="(item,index) in tableListData"
                  :key="index"
                  @click="guiDeRouter(item)">
          <div class="contenLeft">
            <img :src="item.logo"
                 alt="">
          </div>
          <div class="contenRight">
            <h5>{{item.company}}</h5>
            <strong>{{item.pavilion_name}}</strong>
            <ul class="rightBom">
              <li>展馆：{{item.name}}</li>
              <li>展区：{{item.area_name}}</li>
            </ul>
            <ul class="rightBom">
              <li>品类：{{item.title}}</li>
              <li>展位：{{item.exhibition_num}}</li>
            </ul>
          </div>
        </van-cell>
      </van-list>
      <van-empty class="noel"
                 description="暂无数据"
                 v-if="tableListData.length<=0" />
      <van-popup v-model="showFilter"
                 position="right"
                 :style="{ width: '80%', height:'100%',overflow:'hidden' }">
        <exhibitor-filter @changeShow="changeShowFilter" />
      </van-popup>
    </div>
  </div>
</template>

<script>
import '../assets/icon/iconfont.css'
import {
  exhibitorList,
} from "@/api/index";
import exhibitorFilter from './exhibitorFilter'

export default {
  components: {
    exhibitorFilter
  },
  data () {
    return {
      tableListData: [],
      tableFilter: [],
      valueSerch: '',
      showFilter: true,
      ShowSerch: false,
      loading: false,
      finished: false,
      page: 1,//请求第几页
      pageSize: 10,//每页请求的数量
      total: 0,
      strStart: '',
      datasFilter: [],
    }
  },
  created () {
    this.initTable();
  },
  watch: {
    'valueSerch': {
      handler (val) {
        this.pageSize = 10;
        this.page = 1;
        this.tableListData = []
        this.strStart = val;
        if (val) {
          this.ShowSerch = true;
        } else {
          // this.tableListData = []
          this.page = 1;
          this.ShowSerch = false;
          window.scrollTo(0, 0);
        }
        this.initTable(val);
      },
      deep: true
    }
  },
  methods: {
    onCancel () {
      this.tableListData = []
      // this.initTable('init');
    },
    initTable () {
      let datas = {
        page: this.page,
        limit: this.pageSize,
        company: this.strStart,
      }
      if (this.strStart == '') {
        this.$delete(datas, 'company')
      }
      if (this.datasFilter) {
        Object.assign(datas, this.datasFilter)
      }
      datas.page = this.page
      exhibitorList(datas).then(res => {
        this.tableFilter = res.data.data;
        this.total = res.data.total;
        this.loading = false;
        if (res.data.data == null || res.data.data.length === 0) {
          // 加载结束
          this.finished = true;
          return;
        }

        // 将新数据与老数据进行合并
        this.tableListData = this.tableListData.concat(res.data.data);
        //如果列表数据条数>=总条数，不再触发滚动加载
        if (this.tableListData.length >= this.total) {
          this.finished = true;
        } else {
          this.finished = false;
        }
      })
    },
    onLoad () {
      this.page++;
      this.initTable();
    },
    changeShowFilter (row, start) {
      // this.datasFilter.push(datas);
      this.datasFilter = row;
      if (start == false) {
        this.page = 1
        this.tableListData = []
      }
      this.initTable()
      this.showFilter = false;
    },
    filterChange () {
      this.showFilter = true;
    },
    guiDeRouter (row) {
      this.$router.push({
        name: 'exhibitorsDetails',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.contentFlex {
  ::v-deep {
    margin-top: 15px;
    padding-bottom: 10px;
    border-radius: 10px;
  }
}
.noel {
  background: #fff;
  height: 100vh;
}
.leftHead {
  ::v-deep {
    .van-icon {
      color: #888;
      font-size: 20px;
    }
    .van-cell {
      padding: 10px 0px;
    }
  }
}
::v-deep {
  .van-search__content {
    background-color: #fff;
  }
  .van-list {
    padding: 0 10px;
    margin-bottom: 18px;
  }
  .van-search {
    padding: 10px 0 8px 10px;
  }
  .van-cell {
    line-height: 17px;
    padding: 0;
    border-radius: 10px;
  }
}
.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f4f5f9;
}
.leftHead {
  flex: 5;
}
.rightHead {
  flex: 1;
  text-align: left;
  i {
    font-size: 28px;
    margin: 5px 0 0 8px;
    display: block;
    color: #4a9655;
  }
}

.contenLeft {
  flex: 3.2;
  position: relative;
  min-height: 100%;
  padding-left: 13px;
  img {
    width: 100%;
  }
}
.contenRight {
  flex: 6;
  margin: 0 auto;
  max-width: 65%;
  padding-right: 5px;
  h5 {
    margin: 0;
    text-align: left;
    padding: 0 0 0 5px;
    font-size: 14px;
  }
  strong {
    display: flex;
    font-size: 12px;
    font-weight: normal;
    color: #48a15e;
    padding: 2px 0 0 5px;
  }
  ul {
    display: flex;
    justify-content: left;
    font-size: 13px;
    color: #838383;
    width: 97%;
    padding: 5px 0 0 5px;
    li {
      flex: 1;
    }
  }
  li {
    list-style: none;
    font-size: 13px;
    color: #838383;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.van-list {
  border-radius: 10px;
  ::v-deep {
    .van-cell__value {
      border-radius: 7px;
      display: flex;
      height: 90px;
      padding: 20px 0;
      background: #fff;
      overflow: hidden;
    }
  }
}
.flexTop {
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
  z-index: 2;
}
.container {
  display: block;
  padding-top: 45px;
}
.filterText {
  font-size: 12px;
  float: left;
  display: block;
  margin: 12px 0 0 5px;
}
</style>
